
export interface IMarketingManager {
  initialize(): void;
}

export class MarketingManager implements IMarketingManager {
  private _window: Window;
  private _url: string;

  private _initHubSpot(): void {
    const { document } = this._window;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = this._url;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  /**
   * The basic marketing manager.
   * @param window A reference to the window object.
   * @param url The url of the script to load.
   */
  constructor(window: Window, url: string) {
    this._window = window;
    this._url = url;
  }

  public initialize(): void {
    this._initHubSpot();
  }
}
