import { stringFormat } from '../../utilities/stringFormat';
import { type IDisposable } from './iDisposable';

/**
 * Resource query item.
 */
export interface II18nDescription {
  /**
   * The id for the description.
   */
  id: string;
  /**
   * The fallback value in case we do not find the item.
   */
  fallback?: string;
}

/**
 * Internationalization manager.
 */
export interface II18nManager extends IDisposable {
  /**
   * Culture of the manager
   */
  culture: string | null;
  /**
   * Set the culture.
   * @param culture The culture identifier.
   */
  setCulture(culture: string): void;

  /**
   * Load the internationalization resources.
   */
  loadResources(): Promise<void>;

  /**
   * Add a translation resource to the manager.
   * @param key The key for the resource.
   * @param value The value for the resource.
   */
  addTranslation(key: string, value: string): void;

  /**
   * Try to translate the give resource.
   * @param query The query for the resource.
   * @param params Extra parameters to use in case the resource supports string format.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translate(query: II18nDescription, ...params: any[]): string;

  /**
   * Transform a text and translate all the parts matching the {i18nCaptureExpression} expression.
   * @param text The text to transform.
   */
  i18nTransform(text: string): string;
}

/**
 * Internationalization manager.
 */
export abstract class I18nManager implements II18nManager {
  public static readonly DEBUG_CULTURE = 'debug-debug';
  private readonly _i18nCaptureExpression: RegExp;
  private readonly _resources: { [id: string]: string };
  private _loadingPromise: Promise<void>;
  private _culture: string | null;

  /**
   * Constructor
   * @param i18nCaptureExpression Regular expression used to match the
   * parts of a text that needs to be translated.
   */
  constructor(i18nCaptureExpression = /\{i18n\.(\w*)\}/gm) {
    this._i18nCaptureExpression = i18nCaptureExpression;
    this._resources = {};
    this._loadingPromise = Promise.resolve();
    this._culture = null;
  }

  /**
   * @inheritdoc
   */
  public dispose(): Promise<void> {
    const keys = Object.keys(this._resources);
    for (const key of keys) {
      delete this._resources[key];
    }
    return Promise.resolve();
  }

  /**
   * @inheritdoc
   */
  public get culture(): string | null {
    return this._culture;
  }

  /**
   * @inheritdoc
   */
  public setCulture(culture: string): void {
    if (this._culture === culture || culture === '') {
      return;
    }
    this._culture = (culture || '').trim();
    if (culture !== I18nManager.DEBUG_CULTURE) {
      this._loadingPromise = this._loadingPromise
        .then(() => {
          const loading = this.loadResources();
          this._loadingPromise = loading;
          return loading;
        });
    }
  }

  protected setCultureInternal(culture: string | null): string | null {
    this._culture = culture;
    return this._culture;
  }

  /**
   * @inheritdoc
   */
  public abstract loadResources(): Promise<void>;

  /**
   * @inheritdoc
   */
  public addTranslation(key: string, value: string): void {
    this._resources[key] = value;
  }

  /**
   * @inheritdoc
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public translate(query: II18nDescription, ...params: any[]): string {
    if (this._culture === I18nManager.DEBUG_CULTURE) {
      return query.id;
    }
    let translation = this._resources[query.id];
    if (typeof translation !== 'string' || translation.length === 0) {
      translation = query.fallback || query.id || '';
    }
    return stringFormat(translation, ...params);
  }

  /**
   * @inheritdoc
   */
  public i18nTransform(text: string): string {
    return !text || text.length === 0
      ? text
      : text.replace(
        this._i18nCaptureExpression,
        (_match: string, capture: string) => { return this.translate({ id: capture }); }
      );
  }
}
