import { type IDateTimeManager } from '../../../index';
import { type ITransformArgument, type ITransform } from '../../../contracts/';

/**
 * DateTimeTransform - Transformer Class
 * @param dateTimeManager used to inject the DateTimeManager
 * @returns new instance of DateTimeTransform
 */
export class DateTimeTransform implements ITransform {
  private _dateTimeManager: IDateTimeManager;

  constructor(
    dateTimeManager: IDateTimeManager) {
    this._dateTimeManager = dateTimeManager;
  }
  /**
   * method to transform a date time string to a specific format
   * @param value date time string to transformed
   * @param args { name: string, value: string }[] array of arguments that can be used to determine the format
   * @returns new format of the date time string
   */
  public transform(value: string, args: ITransformArgument[] = []) {
    const formatter = args.find(item => item.name === 'format');
    const format = typeof formatter !== 'undefined' ? formatter.value : '';
    switch (format) {
      case 'DATE':
        return this._dateTimeManager.asDateString(value, true);
      case 'TIME':
        return this._dateTimeManager.asTimeString(value, true);
      case 'DATE_TIME':
        return this._dateTimeManager.asDateTimeString(value, true);
      case 'ISO':
        return this._dateTimeManager.asCustomString(value, 'yyyy-MM-dd\'T\'HH:mm:ss', true);
      default:
        return this._dateTimeManager.asDateTimeString(value, true);
    }
  }
}

