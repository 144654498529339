export interface IStorage {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
  clear(): void;
}

export interface ILowLevelStorage extends IStorage {
  isAvailable(): boolean;
}

/**
 * Base class for configuring the {LowLevelStorage} instances.
 */
export class LowLevelStorageOptions {
  public namespacePrefix = '';
  public sanitizeKey: (key: string) => string;

  constructor() {
    this.sanitizeKey = key => key.replace(/\./g, '_');
  }
}

/**
 * Base class for low level storage implementers.
 */
export abstract class LowLevelStorage implements ILowLevelStorage {
  protected options: LowLevelStorageOptions;

  /**
   * Create a new instance.
   * @param options Storage options.
   */
  constructor(options: LowLevelStorageOptions) {
    this.options = options;
  }

  abstract isAvailable(): boolean;
  abstract getItem(key: string): string | null;
  abstract setItem(key: string, value: string): void;
  abstract removeItem(key: string): void;
  abstract clear(): void;

  /**
   * Get a key that is safe for the underlying storage.
   * @param key The original key.
   * @returns The sanitized key.
   */
  protected getKey(key: string): string {
    const newKey = this.options.namespacePrefix
      ? `${this.options.namespacePrefix}/${key}`
      : key;

    return this.options.sanitizeKey(newKey);
  }
}
