import { escapeRegExp } from './escapeRegex';

/**
 * A JavaScript equivalent to printf string format.
 * @param pattern The string pattern.
 * @param params The parameters.
 * @returns The formatted string.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const stringFormat = (pattern: string, ...params: any[]): string => {
  // Inspired by https://stackoverflow.com/questions/610406/javascript-equivalent-to-printf-string-format#18234317
  if (!pattern) {
    return '';
  }

  if (params && params.length > 0) {
    const args = typeof params[0] === 'object' && params[0] != null
      ? params[0]
      : Array.prototype.slice.call(params);


    for (const key in args) {

      pattern = pattern.replace(new RegExp(`\\{${escapeRegExp(key)}\\}`, 'gi'), args[key]);
    }
  }
  return pattern;
};
