
import { generateUniqueId, getHashCode, getUrlOrigin } from '../../../utilities/index';
import { type IIframeContentEvents, type IIframeLoader, type IIframeMessage, IframeContentEventType, IframeContentState } from '../../contracts/index';

type MessageEventHandlerFunctionType = (e: MessageEvent) => void;
type GenericEventHandlerFunctionType = (e: Event) => void;

const CLASSES = {
  loaderWrapper: ['iframe-loader-wrapper'],
  loaderWrapperChildMounted: ['iframe-loader-child-mounted'],
  loaderWrapperModal: ['iframe-modal'],
  modalOpenedBody: ['iframe-modal-opened']
};

/**
 * IframeLoader options
 */
export interface IIframeLoaderOptions {
  url: string;
  parent?: string | HTMLElement;
  events?: IIframeContentEvents;
  iframeAttributes?: { [key: string]: string };
  wrapperAttributes?: { [key: string]: string };
  isModal?: boolean;
}

/**
 * Basic implementation of IIframeLoader.
 */
export class IframeLoader implements IIframeLoader {
  protected window: Window;
  private _options: IIframeLoaderOptions | null;
  private _rootElement: HTMLDivElement | null;
  private _iframeId: string;
  private _onMessageReceived: null | MessageEventHandlerFunctionType;
  private _onIframeLoaded: null | GenericEventHandlerFunctionType;
  private _iframeLoaded: boolean;
  private _disposed: boolean;
  private _readyPromise: Promise<void>;
  private _readyPromiseResolver: null | (() => void);
  private _childMounted = false;

  /**
   * Constructor.
   * @param window Reference to the window object.
   * @param options Loader options.
   */
  constructor(window: Window, options: IIframeLoaderOptions) {
    if (!window)
      throw new Error('The "window" argument is required.');

    if (!options?.url)
      throw new Error('The "options.url" value should be a non-empty string.');

    this.window = window;
    this._options = options;
    this._rootElement = null;
    this._iframeId = '';
    this._disposed = false;
    this._onMessageReceived = this._windowMessageHandler.bind(this);
    this.window.addEventListener('message', this._onMessageReceived);
    this._onIframeLoaded = this._iframeLoadedHandler.bind(this);
    this._iframeLoaded = false;
    this._readyPromiseResolver = null;
    this._readyPromise = new Promise(res => {
      this._readyPromiseResolver = res;
    });
    this._init();
  }

  private _init(): void {
    this._triggerEvent(IframeContentEventType.BeforeCreate);

    this._createRootElement();
    this._createIframe();

    this._triggerEvent(IframeContentEventType.Created);
  }

  private _setAttributes(el: HTMLElement, attributes?: { [key: string]: string }): void {
    if (attributes) {
      const keys = Object.keys(attributes);
      for (const [index] of keys.entries()) {
        const key = keys[index];
        switch (key) {
          case 'class':
          case 'classList': {
            const classes = (attributes[key] || '').split(/(\s+)/).map(d => d.trim()).filter(f => f.length > 0);
            el.classList.add(...classes);
            break;
          }
          default:
            el.setAttribute(key, attributes[key]);
            break;
        }

      }
    }
  }

  private _createIframe(): void {

    const iframe = this.window.document.createElement('iframe');
    const opt = this.getOptions();
    this._setAttributes(iframe, opt.iframeAttributes);
    this._iframeId = generateUniqueId(this.window.document, 'iframe-loader-content-');
    iframe.addEventListener('load', this._onIframeLoaded as GenericEventHandlerFunctionType);
    iframe.setAttribute('src', opt.url);
    iframe.setAttribute('id', this._iframeId);
    this._setAttributes(this._rootElement!, opt.wrapperAttributes);
    this._rootElement!.appendChild(iframe);
    if (opt.isModal) {
      this._rootElement!.classList.add(...CLASSES.loaderWrapperModal);
      this.window.document.body.classList.add(...CLASSES.modalOpenedBody);
    }
  }

  private _createRootElement(): void {
    const parent = this._getParentElement();
    this._rootElement = this.window.document.createElement('div');
    this._rootElement.classList.add(...CLASSES.loaderWrapper);
    parent.appendChild(this._rootElement);
  }

  private _getParentElement(): HTMLElement {
    let parent: HTMLElement | null = null;

    const opt = this.getOptions();
    if (opt.parent) {
      if (typeof opt.parent === 'string') {
        parent = this.window.document.querySelector(opt.parent);
      }
      else {
        parent = opt.parent;
      }
    }

    if (!parent)

      throw new Error(`Failed to find parent "${opt.parent}".`);

    return parent;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _triggerEvent(eventType: IframeContentEventType, data?: any): void {
    const opt = this.getOptions();
    const handler = opt.events
      ? opt.events[eventType]
      : undefined;

    if (handler) {
      try {
        handler({
          type: eventType,
          el: this._rootElement,
          parentEl: this._getParentElement(),
          id: this._iframeId,
          data: data
        });
      } catch (error: unknown) {
        if (console && typeof console.error === 'function') {
          console.error(`Calling the "${eventType}" handler failed.`, error);
        }
      }
    }
  }

  private _iframeLoadedHandler(): void {
    if (this._iframeLoaded)
      return;

    this._iframeLoaded = true;
    this._triggerEvent(IframeContentEventType.BeforeMount);
  }

  private _windowMessageHandler(event: MessageEvent): void {
    if (event.origin !== this._getIframeOrigin())
      return;

    const messageData = event.data
      ? event.data as IIframeMessage
      : null;

    if (!messageData) {
      return;
    }

    if (this._shouldShakeHands(messageData)) {
      this._shakeHands(messageData);
      return;
    }

    if (!messageData.id || messageData.id !== this._iframeId)
      return;

    switch (messageData.state) {
      case IframeContentState.Mounted:
        if (!this._childMounted) {
          this._childMounted = true;
          this._triggerEvent(IframeContentEventType.Mounted);
          this._rootElement!.classList.add(...CLASSES.loaderWrapperChildMounted);
          this._readyPromiseResolver!();
          this._readyPromiseResolver = null;
        }
        break;
      case IframeContentState.BeforeUpdate:
        this._triggerEvent(IframeContentEventType.BeforeUpdate);
        break;
      case IframeContentState.Updated:
        this._triggerEvent(IframeContentEventType.Updated);
        break;
      case IframeContentState.Destroyed:
        void this.dispose();
        break;
      default:
        if (messageData.data && typeof messageData.data === 'string') {
          this._triggerEvent(IframeContentEventType.Data, JSON.parse(messageData.data));
        }
        break;
    }
  }

  private _getIframeOrigin(): string {
    return getUrlOrigin(this.window.document, this.getOptions().url);
  }

  private _shouldShakeHands(message: IIframeMessage): boolean {
    // Handshake did not take place
    if (!message.id && message.state === IframeContentState.Mounted)
      return true;

    return false;
  }

  private _shakeHands(requestMessage: IIframeMessage): void {
    const hash = getHashCode(this._iframeId).toString(10);
    const responseMessage: IIframeMessage = {
      id: '',
      state: IframeContentState.Mounted
    };

    // We got a message back so if the data matches the hash we sent send the id
    if (requestMessage.data && requestMessage.data === hash) {
      responseMessage.id = this._iframeId;
    } else {
      responseMessage.data = hash;
    }

    this.postMessageToChildCore(responseMessage);
  }

  /**
   * Get the IFRAME element.
   * @returns The IFRAME element or null if not found.
   */
  protected getIframe(): HTMLIFrameElement | null {
    return this._rootElement!.querySelector('iframe')!;
  }

  /**
   * Get the current options of the loader.
   * @returns The current options object.
   */
  protected getOptions(): IIframeLoaderOptions {
    return (this._options as IIframeLoaderOptions);
  }

  /**
   * Send a message to the child IFRAME.
   * @param message The message data.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected postMessageToChildCore(message: any): void {
    this.getIframe()!.contentWindow!.postMessage(message, this._getIframeOrigin());
  }

  /**
   * @inheritdoc
   */
  public get iframeId(): string {
    return this._iframeId;
  }

  /**
   * @inheritdoc
   */
  public async waitMount(): Promise<IIframeLoader> {
    await this._readyPromise;

    return this;
  }
  /**
   * @inheritdoc
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public messageChild(data: any): void {
    if (!this._childMounted)
      throw new Error('Wait for the content to mount before sending messages!');

    const message: IIframeMessage = {
      id: this._iframeId,
      data: JSON.stringify(data)
    };

    this.postMessageToChildCore(message);
  }
  /**
   * @inheritdoc
   */
  public async dispose(): Promise<void> {
    if (this._disposed)
      return;

    this._disposed = true;
    this._triggerEvent(IframeContentEventType.BeforeDestroy);

    this.getIframe()!.removeEventListener('load', this._onIframeLoaded!);
    this._iframeLoaded = false;
    this.window.document.body.classList.remove(...CLASSES.modalOpenedBody);
    this._rootElement!.parentElement!.removeChild(this._rootElement!);
    this._rootElement!.classList.remove(...CLASSES.loaderWrapperModal);
    this._rootElement!.classList.remove(...CLASSES.loaderWrapper);
    this._rootElement = null;

    this.window.removeEventListener('message', this._onMessageReceived!);
    this._onMessageReceived = null;

    this._triggerEvent(IframeContentEventType.Destroyed);
    this._options = null;
    this._readyPromiseResolver = null;
  }

}
