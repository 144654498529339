/**
 * A HTTP Request
 */
export interface IHttpRequest {
  /**
   * The URL for the resource.
   */
  url: string;
  /**
   * The HTTP method.
   */
  method?: string;
  /**
   * Any extra HTTP headers.
   */
  headers?: { [key: string]: string };

  /**
   * Any data to send.
   */
  body?: string;
}

/**
 * THe HTTP Response
 */
export interface IHttpResponse {
  /**
   * The original request.
   */
  request: XMLHttpRequest;

  /**
   * The progress event.
   */
  event: ProgressEvent;
}

/**
 * Make an async HTTP request.
 * @param httpRequest The request description.
 * @returns A promise that resolves when the request is done.
 */
export const sendAsync = (httpRequest: IHttpRequest): Promise<IHttpResponse> => {
  if (!httpRequest) {
    return Promise.reject(new Error('No "httpRequest"!'));
  }

  if (!httpRequest.url) {
    return Promise.reject(new Error('No "httpRequest.url"!'));
  }

  const url = httpRequest.url;
  const method = httpRequest.method || 'GET';

  return new Promise<IHttpResponse>((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open(method, url);

    if (httpRequest.headers) {
      const keys = Object.keys(httpRequest.headers);
      for (const key of keys) {
        request.setRequestHeader(key, httpRequest.headers[key]);
      }
    }

    request.onload = function(e: ProgressEvent) {
      resolve({
        request: this,
        event: e
      });
    };

    request.onerror = function(e: ProgressEvent) {
      reject({
        request: this,
        event: e
      });
    };

    if(httpRequest.body) {
      request.send(httpRequest.body);
    } else {
      request.send();
    }
  });
};
