import { type ILowLevelStorage } from './low-level-storage';

export class InMemoryStorage implements ILowLevelStorage {
  private _backingFiled: Record<string, string|undefined>;

  constructor() {
    this._backingFiled = {};
  }

  public isAvailable(): boolean {
    return true;
  }

  public getItem(key: string): string | null {
    return this._backingFiled[key] || null;
  }

  public setItem(key: string, value: string): void {
    this._backingFiled[key] = value;
  }

  public removeItem(key: string): void {
    this._backingFiled[key] = undefined;
  }

  public clear(): void {
    Object.keys(this._backingFiled).forEach((key: string) => {
      this._backingFiled[key] = undefined;
    });
  }
}
