
import { InMemoryStorage } from './in-memory-storage';
import type { LowLevelStorage, LowLevelStorageOptions } from './low-level-storage';
import { type IStorage } from './low-level-storage';

export type LowLevelStorageConstructor<T extends LowLevelStorage> = new (window: Window, options: LowLevelStorageOptions) => T;

export class StorageFactory<T extends LowLevelStorage> {
  protected genericStorageOptions: LowLevelStorageOptions | null = null;
  protected storageRef: IStorage | null = null;
  protected window: Window;
  protected lowLevelStorageConstructor : LowLevelStorageConstructor<T>;

  constructor(window: Window, lowLevelStorageConstructor: LowLevelStorageConstructor<T>) {
    this.window = window;
    this.lowLevelStorageConstructor = lowLevelStorageConstructor;
  }

  public init(options: LowLevelStorageOptions): void {
    if (!this.genericStorageOptions) {
      this.genericStorageOptions = options;
    }
  }

  public getStorage(preferMemory: boolean): IStorage {
    if (this.storageRef != null) {
      return this.storageRef;
    }

    if (!preferMemory) {
      if (!this.genericStorageOptions) {
        this.storageRef = null;
        throw new Error('genericStorageOptions is not initialized.');
      } else {
        const storage: LowLevelStorage = new this.lowLevelStorageConstructor(this.window, this.genericStorageOptions);
        if (storage.isAvailable()) {
          this.storageRef = storage;
        }
      }
    }

    if (this.storageRef === null) {
      this.storageRef = new InMemoryStorage();
    }

    return this.storageRef;
  }
}
