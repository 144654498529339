
/**
 * A promise wrapper around setTimeout.
 * @param delay The number of milliseconds to delay the promise.
 * @param success Should the promise resolve or reject?
 */
export const getDelayPromise = (delay = 1, success = true): Promise<void> => {
  return new Promise((res, rej) =>{
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      success ? res() : rej();
    }, delay);
  });
};

/**
 * A promise the resolves after a given number of ticks.
 * @param count The number of ticks to wait for.
 * @returns A promise that resolves after the given number of ticks.
 */
export const nextTicks = (count = 1): Promise<void> => {
  return count === 0
    ? Promise.resolve()
    : getDelayPromise(7, true).then(() => nextTicks(count - 1));
}
