/**
 * Returns the pathname of an url.
 * @param document the document object
 * @param url the given url
 * @returns the pathname
 */
export const getPathname = (document: Document, url: string): string => {
  if (!url)
    return '';

  const a = document.createElement('a');
  a.setAttribute('href', url);

  return a.pathname;
};
