import * as utils from '@adapt-arch/utiliti-es';
import { type IMessageBus, BusMessage, type IListener } from '../contracts/index';

/**
 * @inheritdoc
 */
export class MessageBus implements IMessageBus {

  private _bus: utils.PubSubHub;
  private _handlers: {id: string, listener:  IListener}[];

  /**
   * @inheritdoc
   */
  constructor() {
    this._bus = new utils.PubSubHub();
    this._handlers = [];
  }

  /**
   * @inheritdoc
   */
  public subscribe(message: string, listener: IListener): IMessageBus {
    const handler: utils.MessageHandler = (t, m) => {
      listener(t, BusMessage.fromJson(m.__messagePayload as string));
    };
    const id = this._bus.subscribe(message, handler);
    if (id) {
      this._handlers.push({ id, listener });
    }
    return this;
  }

  /**
   * @inheritdoc
   */
  unsubscribe(_message: string, listener: IListener): IMessageBus {
    const id = this._handlers.find(h => h.listener === listener)?.id;
    if (id) {
      this._bus.unsubscribe(id);
    }
    return this;
  }

  /**
   * @inheritdoc
   */
  publish<T>(message: BusMessage<T>): IMessageBus {
    this._bus.publish(message.name, {__messagePayload: BusMessage.toJson(message)});
    return this;
  }

  /**
   * @inheritdoc
   */
  public async dispose(): Promise<void> {
    try {
      await this._bus[Symbol.dispose]();
    } catch {
      // Do nothing.
    }
  }
}
