import { type ITransform, type ITransformRepository } from '../contracts';

export type ITransformDictionary<T> = {
  [key: string]: T | undefined;
};

/**
 * TransformRepository - TransformRepository Class
 */
export class TransformRepository implements ITransformRepository {
  private _transforms: ITransformDictionary<ITransform> = {};

  /**
   * register
   * @param id string used to identify the transform
   * @param valueTransform transform to be registered
   * @returns true if the transform was registered, false if the transform was already registered
   */
  public register(id: string, valueTransform: ITransform): boolean {
    if (this._transforms[id]) {
      return false; // already registered
    }
    this._transforms[id] = valueTransform;
    return true;  // registered
  }

  /**
   * resolve
   * @param id string used to identify the transform
   * @returns  transform if the transform was registered, null if the transform was not registered
   */

  public resolve(id: string): ITransform| null {
    return this._transforms[id] ?? null;
  }
}





