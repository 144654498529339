import { type IUserManager } from '../iUserManager';
import type { User } from '../user';

/**
 * A user manger implementation that handles anonymous users.
 */
export class AnonymousUserManager implements IUserManager {
  /**
   * @inheritdoc
   */
  initialize(): Promise<void> {
    return Promise.resolve();
  }
  /**
   * @inheritdoc
   */
  signIn(): Promise<void> {
    return Promise.resolve();
  }
  /**
   * @inheritdoc
   */
  signOut(): Promise<void> {
    return Promise.resolve();
  }
  /**
   * @inheritdoc
   */
  getUser(): Promise<User | null> {
    return Promise.resolve(null);
  }
  /**
   * @inheritdoc
   */
  addUserSessionChangedHandler(): void {
    /* NOOP - anonymous users do not change so we do not need to trigger this. */
  }
  /**
   * @inheritdoc
   */
  removeUserSessionChangedHandler(): void {
    /* NOOP - anonymous users do not change so we do not need to trigger this. */
  }
  /**
   * @inheritdoc
   */
  dispose(): Promise<void> {
    return Promise.resolve();
  }
}
