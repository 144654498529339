/**
 * Get a hash code for the given string
 * @param value The string to get the hash code for
 * @returns The has code
 */
const getHashCode = (value: string): number => {
  let hash = 0;
  const length = value.length;
  let char;
  let index = 0;
  if (length === 0)
    return hash;

  while (index < length) {
    char = value.charCodeAt(index);
    hash = ((hash << 5) - hash) + char;
    hash |= 0; // Convert to 32bit integer

    index++;
  }

  return hash;
};


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getObjectHashCode = (value: any): number => {
  return getHashCode(JSON.stringify(value) || '');
};
export { getHashCode, getObjectHashCode };
