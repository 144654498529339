const regexEscapeRE = /[.*+?^${}()|[\]\\]/g;
/**
 * Escape a value for use within a regular expression.
 * @param stringValue The value to escape.
 * @returns The escaped value.
 */
export const escapeRegExp = (stringValue: string): string => {
  // From: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
  return stringValue.replace(regexEscapeRE, '\\$&');
};
