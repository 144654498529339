import { type II18nManager } from '../../../../core-components';
import { type ITransform } from '../../../contracts/';

/**
 * YesNoTransform - Transformer Class
 * @param resourceManager used to inject the II18nManager
 * @returns new instance of YesNoTransform
 */
export class YesNoTransform implements ITransform {
  private _resourceManager: II18nManager;

  constructor(resourceManager: II18nManager) {
    this._resourceManager = resourceManager;
  }

  /**
   * method to generate correct translation for Yes or No
   * @param value string to be translated
   * @returns the translated value for Yes or No based on the value length
   */
  public transform(value?: string ) {
    return value ? this._resourceManager.i18nTransform('{i18n.CMN_Yes}') : this._resourceManager.i18nTransform('{i18n.CMN_No}');
  }
}

