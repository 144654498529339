

import { type IHelpCenterService } from '../../../auxiliary-components/index';
import { type ISetHelpCenterSuggestions } from '../../../messages/index';
import { getNonce } from '../../../utilities/getNonce';
import { getObjectHashCode, rgbToHex } from '../../../utilities/index';

/**
 * Zendesk Widget configuration.
 *
 */
export interface IZendeskHelpCenterServiceOptions {
  themeColor: string;
  locale: string;
  scriptUrl: string;
  retryInterval?: number;
}

type ZendeskGlobalApi = (component: string, ctorOrMethod: string | unknown, args?: unknown) => void;

/**
 * A proxy service over the ZenDesk object in window, that exposes the necessary API into the application
 * Based on https://developer.zendesk.com/embeddables/docs/widget/introduction
 */
export class ZendeskHelpCenterService implements IHelpCenterService {
  private static get RETRY_INTERVAL() { return 5_000; }

  private _options: IZendeskHelpCenterServiceOptions;
  private _lastHashCode = 0;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private get _zE(): ZendeskGlobalApi { return (this._window as any).zE; }
  private _window: Window;

  /**
   * Basic help center component.
   * @param window A window reference.
   * @param options The Zendesk options.
   */
  constructor(window: Window, options: IZendeskHelpCenterServiceOptions) {
    this._window = window;
    this._options = options;
  }

  public async initialize(): Promise<void> {
    const scriptId = 'ze-snippet';
    const win = this._window;
    let scriptEl = win.document.getElementById(scriptId) as HTMLScriptElement;

    if (!scriptEl) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (win as any).zESettings = {
        webWidget: {
          color: {
            theme: this._options.themeColor[0] === '#' ? this._options.themeColor : rgbToHex(this._options.themeColor)
          }
        }
      };

      scriptEl = win.document.createElement('script');
      scriptEl.id = scriptId;
      scriptEl.src = this._options.scriptUrl;
      scriptEl.nonce = getNonce(win, 'script');
      win.document.head.appendChild(scriptEl);
    }
    this.setLocale(this._options.locale);
  }

  private isZendeskInitialized(): boolean {
    return typeof this._zE === 'function';
  }

  private widgetChangeUtil(operation: string) {
    if (this.isZendeskInitialized()) {
      this._zE('webWidget', operation);
    }
  }
  /**
   * Forces the widget to appear.
   * Based on https://developer.zendesk.com/embeddables/docs/widget/core#open
   */
  public openWidget(): void {
    this.widgetChangeUtil('open');
  }

  /**
   * If the widget is opened, this api will close the widget and show the launcher
   * Based on https://developer.zendesk.com/embeddables/docs/widget/core#close
   */
  public closeWidget(): void {
    this.widgetChangeUtil('close');
  }

  /**
   * Displays the widget on the host page in the state it was in before it was hidden
   * Based on https://developer.zendesk.com/embeddables/docs/widget/core#show
   */
  public showWidget(): void {
    this.widgetChangeUtil('show');
  }

  /**
   * Hides all parts of the Widget from the page
   * Based on https://developer.zendesk.com/embeddables/docs/widget/core#hide
   */
  public hideWidget(): void {
    this.widgetChangeUtil('hide');
  }

  /**
   * Sets the widget locale.
   * Based on https://developer.zendesk.com/embeddables/docs/widget/core#setlocale
   * @param locale The locale to set.
   */
  public setLocale(locale: string): void {
    if (this.isZendeskInitialized() && locale.length > 0) {
      this._zE('webWidget', 'setLocale', locale);
    }
  }

  /**
   * Enhances the contextual help provided by the Web Widget
   * If you pass both search strings and labels, the labels are ignored
   * Based on https://developer.zendesk.com/embeddables/docs/widget/help_center#helpcentersetsuggestions
   * @param payload The payload to set.
   * @param retries The number of retries to attempt.
   */
  public setHelpCenterSuggestions(payload: ISetHelpCenterSuggestions, retries = 5): void {

    if (retries > 0) {
      if (!this.isZendeskInitialized()) {
        setTimeout(() => this.setHelpCenterSuggestions(payload, --retries), this._options.retryInterval ?? ZendeskHelpCenterService.RETRY_INTERVAL);
        return;
      }

      const hashCode = getObjectHashCode(payload);
      if (this._lastHashCode === hashCode) {
        return;
      }

      this._lastHashCode = hashCode;
      this._zE('webWidget', 'helpCenter:setSuggestions', payload);
    }
  }
}
