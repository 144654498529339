/**
 * Set the provided style on the document.
 * @param document The reference to the document object.
 * @param style The style to set.
 * @param id The id of the element to change. In case you want to change style multiple times use the same value to update. If the id is not found or not provided a new element is added.
 */
const setStyle = (document: Document, style: string, id?: string): void => {
  if (!document)
    throw Error('The "document" reference is required!');

  let linkElement = id ? document.getElementById(id) : null;
  if (!linkElement) {
    linkElement = document.createElement('link');
    if (id) {
      linkElement.setAttribute('id', id);
    }
    linkElement.setAttribute('rel', 'stylesheet');
    linkElement.setAttribute('type', 'text/css');
    document.head.appendChild(linkElement);
  }

  linkElement.setAttribute('href', 'data:text/css;charset=UTF-8,' + (style ? encodeURIComponent(style) : ''));
};

export { setStyle };
