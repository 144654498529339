import { i18N } from '../../../../../src/constants';
import { BackgroundWorker, BusMessage, type II18nManager, type IMessageBus } from '../../../../core-components/index';
import { type IShowNotification, Types } from '../../../../messages/index';

export abstract class VersionChecker extends BackgroundWorker {
  private readonly _messageBus: IMessageBus;
  private readonly _i18nManager: II18nManager;
  private _autoRefreshDelay: number;
  private _autoRefreshTimeoutRef: number;
  private _continueExecuting: boolean;
  /**
   * Constructor.
   * @param window Operating window object for class
   * @param eventBus Message Bus in which error messages are posted to
   * @param i18nManager For translating outputs
   * @param refreshInterval The interval (in milliseconds) between server side checks.
   * @param autoRefreshDelay The interval (in milliseconds) before the application is automatically refreshed.
   */
  constructor(window: Window, eventBus: IMessageBus, i18nManager: II18nManager, refreshInterval: number, autoRefreshDelay?: number) {
    super(window, refreshInterval, false);
    this._messageBus = eventBus;
    this._i18nManager = i18nManager;
    this._continueExecuting = true;
    this._autoRefreshDelay = autoRefreshDelay ?? 12 * 60 * 60 * 1000; // 12 * 60 * 60 * 1000 = 12 hour(s) * 60 minutes * 60 seconds * 1000 milliseconds
    this._autoRefreshTimeoutRef = 0;
  }

  private _handleNewerVersion(): void {
    this._scheduleAutoUpdate();
    const messageGroup = 'cmn.versionChecker';
    this._messageBus.publish<IShowNotification>(new BusMessage<IShowNotification>(
      Types.C.CMN_NOTIFICATION_SHOW,
      {
        title: this._i18nManager.translate(i18N.CMN_WhatsNew),
        body: this._i18nManager.translate(i18N.CMN_NewVersionNotification),
        dismissible: false,
        group: messageGroup,
        single: true
      }
    ));
  }

  private _scheduleAutoUpdate(): void {
    this.window.clearTimeout(this._autoRefreshTimeoutRef);
    this._autoRefreshTimeoutRef = this.window.setTimeout(
      () => this.window.location.reload(),
      this._autoRefreshDelay
    );
  }

  protected abstract isNewerVersionAvailable(): Promise<boolean>;

  protected async execute(): Promise<void> {
    if (!this._continueExecuting) {
      return;
    }

    const newerAvailable = await this.isNewerVersionAvailable();
    if (newerAvailable) {
      this._continueExecuting = false;
      this._handleNewerVersion();
    }
  }
}
