/**
 * Get the CSS COLOR property.
 * @param window A reference to the window.
 * @param className The name of the class.
 * @returns The color of the class.
 */
export const getClassColor = (window: Window, className: string): string => {
  const elem = window.document.createElement('div');
  elem.style.position = 'absolute';
  elem.style.top = '-9999px';
  elem.style.left = '-9999px';
  elem.className = className;
  window.document.body.appendChild(elem);
  const color = window.getComputedStyle(elem).color;
  window.document.body.removeChild(elem);
  return color || '';
};
