/**
 * Chart colors configuration.
 */
export interface IChartColors {
  /**
   * Primary color.
   */
  primary: string;
  /**
   * Primary color a bit transparent.
   */
  primaryTransparent: string;
  /**
   * Secondary color.
   */
  secondary: string;
  /**
   * Secondary color a bit transparent.
   */
  secondaryTransparent: string;
}

/**
 * @inheritdoc
 */
export class ChartColors implements IChartColors {
  /**
   * @inheritdoc
   */
  public primary = '';
  /**
   * @inheritdoc
   */
  public primaryTransparent = '';
  /**
   * @inheritdoc
   */
  public secondary = '';
  /**
   * @inheritdoc
   */
  public secondaryTransparent = '';
  /**
   * Constructor.
   * @param fields Chart colors.
   */
  constructor(fields?: Partial<IChartColors>) {
    this.overrideValues(fields);
  }

  /**
   * Override the values.
   * @param fields Chart colors.
   * @returns Chart colors.
   */
  public overrideValues(fields: Partial<IChartColors> | undefined): ChartColors {
    if (!fields)
      return this;

    this.primary = fields.primary ?? this.primary;
    this.primaryTransparent = fields.primaryTransparent ?? this.primaryTransparent;
    this.secondary = fields.secondary ?? this.secondary;
    this.secondaryTransparent = fields.secondaryTransparent ?? this.secondaryTransparent;
    return this;
  }
}

/**
 * Theme colors.
 */
export interface IThemeColors {
  /**
   * Primary color.
   */
  primary: string;
  /**
   * Secondary color.
   */
  secondary: string;
}

/**
 * @inheritdoc
 */
export class ThemeColors implements IThemeColors {
  /**
   * @inheritdoc
   */
  public primary = '';
  /**
   * @inheritdoc
   */
  public secondary = '';
  /**
   * Constructor.
   * @param fields Theme colors.
   */
  constructor(fields?: Partial<IThemeColors>) {
    this.overrideValues(fields);
  }

  /**
   * Override the values.
   * @param fields Theme colors.
   * @returns Theme colors.
   */
  public overrideValues(fields: Partial<IThemeColors> | undefined): ThemeColors {
    if (!fields)
      return this;

    this.primary = fields.primary ?? this.primary;
    this.secondary = fields.secondary ?? this.secondary;
    return this;
  }
}

/**
 * Customization settings.
 */
export interface ICustomizationOptions {
  /**
   * Customization realm id.
   */
  realm: string;
  /**
   * Culture in the `en-GB` or `en` format.
   */
  culture: string;
  /**
   * ISO A2 country code.
   */
  country: string;
  /**
   * Date format.
   */
  dateFormat: string;
  /**
   * Time format.
   */
  timeFormat: string;
  /**
   * IANA timezone.
   */
  timeZone: string;
  /**
   * The unit system.
   */
  unitSystem: string;
  /**
   * Length units format.
   */
  lengthFormat: string;
  /**
   * Weight units format.
   */
  weightFormat: string;
  /**
   * Volume units format.
   */
  volumeFormat: string;
  /**
   * Theme identifier.
   */
  theme: string;
  /**
   * Theme colors.
   */
  themeColors: IThemeColors;
  /**
   * Chart colors.
   */
  chartColors: IChartColors;
  /**
   * Debug parameter
   * Overrides the culture of the manager
   * If provided with value 'debug-debug' displays the translations keys instead of the translated strings
   */
  '__debug-culture'?: string;
}

export class CustomizationOptions implements ICustomizationOptions {
  /**
   * @inheritdoc
   */
  public realm = '';
  /**
   * @inheritdoc
   */
  public culture = 'en-GB';
  /**
   * @inheritdoc
   */
  public country = '';
  /**
   * @inheritdoc
   */
  public dateFormat = 'dd/MM/yyyy';
  /**
   * @inheritdoc
   */
  public timeFormat = 'HH:mm:ss';
  /**
   * @inheritdoc
   */
  public timeZone = '';
  /**
   * @inheritdoc
   */
  public unitSystem = 'metric';
  /**
   * @inheritdoc
   */
  public lengthFormat = 'centimeter';
  /**
   * @inheritdoc
   */
  public weightFormat = 'kilogram';
  /**
   * @inheritdoc
   */
  public volumeFormat = 'liter';
  /**
   * @inheritdoc
   */
  public theme = 'Official';
  /**
   * @inheritdoc
   */
  public themeColors: IThemeColors = new ThemeColors();
  /**
   * @inheritdoc
   */
  public chartColors: IChartColors = new ChartColors();
  /**
   * Constructor.
   * @param fields Customization settings values.
   */
  constructor(fields?: Partial<ICustomizationOptions>) {
    this.overrideValues(fields);
  }


  /**
   * Override the values.
   * @param fields Customization settings values
   * @returns Customization settings.
   */
  public overrideValues(fields: Partial<ICustomizationOptions> | undefined): CustomizationOptions {
    if (!fields)
      return this;

    this.realm = fields.realm ?? this.realm;
    this.culture = fields.culture ?? this.culture;
    this.country = fields.country ?? this.country;
    this.dateFormat = fields.dateFormat ?? this.dateFormat;
    this.timeFormat = fields.timeFormat ?? this.timeFormat;
    this.timeZone = fields.timeZone ?? this.timeZone;
    this.unitSystem = fields.unitSystem ?? this.unitSystem;
    this.lengthFormat = fields.lengthFormat ?? this.lengthFormat;
    this.weightFormat = fields.weightFormat ?? this.weightFormat;
    this.volumeFormat = fields.volumeFormat ?? this.volumeFormat;
    this.theme = fields.theme ?? this.theme;
    this.themeColors = new ThemeColors(this.themeColors).overrideValues(fields.themeColors);
    this.chartColors = new ChartColors(this.chartColors).overrideValues(fields.chartColors);

    return this;
  }
}
