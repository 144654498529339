
export const loadResource = (
  document: Document,
  url: string,
  isScript: boolean,
  skipLoading: boolean,
  attributes?: { [key: string]: string },
  timeout?: number
): Promise<void> => {
  const timeoutDuration = typeof timeout === 'number' ? timeout : 30_000;
  let timeoutRef = 0;
  let resource: HTMLScriptElement | HTMLLinkElement | null = null;
  return new Promise<void>((resolve, reject) => {
    const cleanUp = () => {
      resource!.removeEventListener('load', loadEventHandler);
      resource!.removeEventListener('error', errorEventHandler);
      document.defaultView!.clearTimeout(timeoutRef);
      timeoutRef = 0;
    };
    const loadEventHandler = () => {
      cleanUp();
      resolve();
    };
    const errorEventHandler = () => {
      cleanUp();
      reject(new Error(`Script load error for url: ${url}.`));
    };
    if (isScript) {
      resource = document.createElement('script');
      (resource).src = url;
    } else {
      resource = document.createElement('link');
      (resource).href = url;
      (resource).rel = 'stylesheet';
    }
    if (attributes) {
      const keys = Object.keys(attributes);
      for (const key of keys) {
        resource.setAttribute(key, attributes[key]);
      }
    }
    resource.addEventListener('error', errorEventHandler);
    resource.addEventListener('load', loadEventHandler);
    document.head.appendChild(resource);
    // skips waiting for the element to load if specified
    if (skipLoading) {
      loadEventHandler();
    }
    // after the specified timeout, rejects for not loading in time
    timeoutRef = document.defaultView!.setTimeout(() => {
      cleanUp();
      reject(new Error(`Script load timeout for url: ${url}.`));
    }, timeoutDuration);
  });
};
