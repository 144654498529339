import { getUuidV4, type SerializableValues } from '../../../utilities';

/**
 * MessageBus message.
 */
export class BusMessage<T> {

  /**
   * Create a new bus message.
   * @param name The name of the message.
   * @param data The data of the message.
   * @param correlationId The uuid for the message that this is a response to.
   * @param uuid The uuid for the message in case you are re-publishing the message and need to preserve it.
   * @param timestamp The timestamp for the message in case you are re-publishing the message and need to preserve it.
   */
  constructor(name: string, data: T, correlationId?: string, uuid?: string, timestamp?: number) {
    this.name = name;
    this.data = data;
    this.correlationId = correlationId;
    this.uuid = uuid || getUuidV4();
    this.timestamp = timestamp || new Date().getTime();
    this.metadata = {};
  }

  /**
   * The name of the message.
   */
  public name: string;
  /**
   * The message universal unique identifier.
   */
  public uuid: string;
  /**
   * The message uuid of the original message in case this is a `response` to another message.
   */
  public correlationId?: string;
  /**
   * The message timestamp.
   */
  public timestamp: number;
  /**
   * The message data.
   */
  public data: T;
  /**
   * The message data.
   */
  public metadata: { [key: string]: SerializableValues };

  public static toJson<T>(message: BusMessage<T>): string {
    return JSON.stringify({
      name: message.name,
      uuid: message.uuid,
      correlationId: message.correlationId,
      timestamp: message.timestamp,
      data: message.data,
      metadata: message.metadata
    });
  }

  public static fromJson<T>(json: string): BusMessage<T> {
    const messageJson = JSON.parse(json) as BusMessage<T>;
    const message = new BusMessage<T>(messageJson.name, messageJson.data, messageJson.correlationId, messageJson.uuid, messageJson.timestamp);
    message.metadata = messageJson.metadata;
    return message;
  }
}
