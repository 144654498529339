import { i18N } from '../../constants';
import { type II18nManager } from '../../core-components';
import { type KeyValuePair, type NumberDictionary } from '../../utilities/index';
import { type IMeasurementsManager, type IMeasurementsManagerOptions, LengthUnit, SpeedUnit, VolumeUnit, WeightUnit } from '../contracts/index';

export class MeasurementsManager implements IMeasurementsManager {

  private readonly _options: IMeasurementsManagerOptions;
  private readonly _i18nManager: II18nManager;

  constructor(i18nManager: II18nManager, options: IMeasurementsManagerOptions) {
    this._options = options;
    this._i18nManager = i18nManager;

    MeasurementsManager._initializeCoefficients();
  }

  public static readonly RoundingPrecision: number = 4;
  public static readonly RoundingCoefficient: number = Math.pow(10, MeasurementsManager.RoundingPrecision);

  private static _coefficientsInitialized = false;
  private static _lengthMatrix: NumberDictionary<NumberDictionary<number>>;
  private static _metricLengthCoefficients: NumberDictionary<number>;
  private static _imperialLengthCoefficients: NumberDictionary<number>;

  private static _volumeMatrix: NumberDictionary<NumberDictionary<number>>;
  private static _metricVolumeCoefficients: NumberDictionary<number>;
  private static _imperialVolumeCoefficients: NumberDictionary<number>;
  private static _usVolumeCoefficients: NumberDictionary<number>;
  private static _cubicInchesVolumeCoefficients: NumberDictionary<number>;

  private static _weightMatrix: NumberDictionary<NumberDictionary<number>>;
  private static _metricWeightCoefficients: NumberDictionary<number>;
  private static _imperialWeightCoefficients: NumberDictionary<number>;

  private static _speedMatrix: NumberDictionary<NumberDictionary<number>>;
  private static _metricSpeedCoefficients: NumberDictionary<number>;
  private static _imperialSpeedCoefficients: NumberDictionary<number>;

  private static _initializeCoefficients(): void {
    if (MeasurementsManager._coefficientsInitialized) {
      return;
    }

    MeasurementsManager._coefficientsInitialized = true;
    MeasurementsManager._initializeLengthCoefficients();
    MeasurementsManager._initializeVolumeCoefficients();
    MeasurementsManager._initializeWeightCoefficients();
    MeasurementsManager._initializeSpeedCoefficients();
  }

  private static _initializeLengthCoefficients(): void {
    MeasurementsManager._lengthMatrix = {};
    MeasurementsManager._lengthMatrix[LengthUnit.Millimeter] = {};
    MeasurementsManager._lengthMatrix[LengthUnit.Inch] = {};

    MeasurementsManager._lengthMatrix[LengthUnit.Millimeter][LengthUnit.Millimeter] = 1;
    MeasurementsManager._lengthMatrix[LengthUnit.Millimeter][LengthUnit.Inch] = 0.0393700787;

    MeasurementsManager._lengthMatrix[LengthUnit.Inch][LengthUnit.Inch] = 1;
    MeasurementsManager._lengthMatrix[LengthUnit.Inch][LengthUnit.Millimeter] = 25.4;


    MeasurementsManager._metricLengthCoefficients = {};
    MeasurementsManager._metricLengthCoefficients[LengthUnit.Millimeter] = 1;
    MeasurementsManager._metricLengthCoefficients[LengthUnit.Centimeter] = 10;
    MeasurementsManager._metricLengthCoefficients[LengthUnit.Decimeter] = 100;
    MeasurementsManager._metricLengthCoefficients[LengthUnit.Meter] = 1000;

    MeasurementsManager._imperialLengthCoefficients = {};

    MeasurementsManager._imperialLengthCoefficients[LengthUnit.Inch] = 1;
    MeasurementsManager._imperialLengthCoefficients[LengthUnit.Feet] = 12;
    MeasurementsManager._imperialLengthCoefficients[LengthUnit.Yard] = 36;
  }

  private static _initializeVolumeCoefficients(): void {
    MeasurementsManager._volumeMatrix = {};

    MeasurementsManager._volumeMatrix[VolumeUnit.CubicMillimeter] = {};
    MeasurementsManager._volumeMatrix[VolumeUnit.CubicInch] = {};
    MeasurementsManager._volumeMatrix[VolumeUnit.OunceUnitedKingdom] = {};
    MeasurementsManager._volumeMatrix[VolumeUnit.OunceUnitedStates] = {};


    MeasurementsManager._volumeMatrix[VolumeUnit.CubicMillimeter][VolumeUnit.CubicMillimeter] = 1;
    MeasurementsManager._volumeMatrix[VolumeUnit.CubicMillimeter][VolumeUnit.OunceUnitedKingdom] = 0.0000351950652;
    MeasurementsManager._volumeMatrix[VolumeUnit.CubicMillimeter][VolumeUnit.OunceUnitedStates] = 0.0000338140227;
    MeasurementsManager._volumeMatrix[VolumeUnit.CubicMillimeter][VolumeUnit.CubicInch] = 0.0000610237441;

    MeasurementsManager._volumeMatrix[VolumeUnit.OunceUnitedKingdom][VolumeUnit.OunceUnitedKingdom] = 1;
    MeasurementsManager._volumeMatrix[VolumeUnit.OunceUnitedKingdom][VolumeUnit.CubicMillimeter] = 28413.0742;
    MeasurementsManager._volumeMatrix[VolumeUnit.OunceUnitedKingdom][VolumeUnit.OunceUnitedStates] = 0.960760338;
    MeasurementsManager._volumeMatrix[VolumeUnit.OunceUnitedKingdom][VolumeUnit.CubicInch] = 1.7338714549;

    MeasurementsManager._volumeMatrix[VolumeUnit.OunceUnitedStates][VolumeUnit.OunceUnitedKingdom] = 1.0408423;
    MeasurementsManager._volumeMatrix[VolumeUnit.OunceUnitedStates][VolumeUnit.CubicMillimeter] = 29573.5296;
    MeasurementsManager._volumeMatrix[VolumeUnit.OunceUnitedStates][VolumeUnit.OunceUnitedStates] = 1;
    MeasurementsManager._volumeMatrix[VolumeUnit.OunceUnitedStates][VolumeUnit.CubicInch] = 1.8046875;

    MeasurementsManager._volumeMatrix[VolumeUnit.CubicInch][VolumeUnit.OunceUnitedKingdom] = 0.576744024;
    MeasurementsManager._volumeMatrix[VolumeUnit.CubicInch][VolumeUnit.CubicMillimeter] = 16387.064;
    MeasurementsManager._volumeMatrix[VolumeUnit.CubicInch][VolumeUnit.OunceUnitedStates] = 0.5541125541;
    MeasurementsManager._volumeMatrix[VolumeUnit.CubicInch][VolumeUnit.CubicInch] = 1;

    MeasurementsManager._metricVolumeCoefficients = {};
    MeasurementsManager._metricVolumeCoefficients[VolumeUnit.CubicMillimeter] = 1;
    MeasurementsManager._metricVolumeCoefficients[VolumeUnit.CubicCentimeter] = 1000;
    MeasurementsManager._metricVolumeCoefficients[VolumeUnit.CubicDecimeter] = 1000000;
    MeasurementsManager._metricVolumeCoefficients[VolumeUnit.Liter] = 1000000;
    MeasurementsManager._metricVolumeCoefficients[VolumeUnit.CubicMeter] = 1000000000;

    MeasurementsManager._imperialVolumeCoefficients = {};
    MeasurementsManager._imperialVolumeCoefficients[VolumeUnit.OunceUnitedKingdom] = 1;
    MeasurementsManager._imperialVolumeCoefficients[VolumeUnit.PintUnitedKingdom] = 20;
    MeasurementsManager._imperialVolumeCoefficients[VolumeUnit.GallonUnitedKingdom] = 160;

    MeasurementsManager._usVolumeCoefficients = {};
    MeasurementsManager._usVolumeCoefficients[VolumeUnit.OunceUnitedStates] = 1;
    MeasurementsManager._usVolumeCoefficients[VolumeUnit.PintUnitedStates] = 16;
    MeasurementsManager._usVolumeCoefficients[VolumeUnit.GallonUnitedStates] = 128;

    MeasurementsManager._cubicInchesVolumeCoefficients = {};
    MeasurementsManager._cubicInchesVolumeCoefficients[VolumeUnit.CubicInch] = 1;
    MeasurementsManager._cubicInchesVolumeCoefficients[VolumeUnit.CubicFoot] = 1728;
  }

  private static _initializeWeightCoefficients(): void {
    MeasurementsManager._weightMatrix = {};
    MeasurementsManager._weightMatrix[WeightUnit.Gram] = {};
    MeasurementsManager._weightMatrix[WeightUnit.Ounce] = {};


    MeasurementsManager._weightMatrix[WeightUnit.Gram][WeightUnit.Gram] = 1;
    MeasurementsManager._weightMatrix[WeightUnit.Gram][WeightUnit.Ounce] = 0.0352739619;

    MeasurementsManager._weightMatrix[WeightUnit.Ounce][WeightUnit.Ounce] = 1;
    MeasurementsManager._weightMatrix[WeightUnit.Ounce][WeightUnit.Gram] = 28.3495231;

    MeasurementsManager._metricWeightCoefficients = {};
    MeasurementsManager._metricWeightCoefficients[WeightUnit.Gram] = 1;
    MeasurementsManager._metricWeightCoefficients[WeightUnit.Kilogram] = 1000;
    MeasurementsManager._metricWeightCoefficients[WeightUnit.Ton] = 1000000;


    MeasurementsManager._imperialWeightCoefficients = {};
    MeasurementsManager._imperialWeightCoefficients[WeightUnit.Ounce] = 1;
    MeasurementsManager._imperialWeightCoefficients[WeightUnit.Pound] = 16;
    MeasurementsManager._imperialWeightCoefficients[WeightUnit.StoneUnitedStates] = 200;
    MeasurementsManager._imperialWeightCoefficients[WeightUnit.StoneUnitedKingdom] = 224;
  }

  private static _initializeSpeedCoefficients(): void {
    MeasurementsManager._speedMatrix = {};
    MeasurementsManager._speedMatrix[SpeedUnit.KilometersPerHour] = {};
    MeasurementsManager._speedMatrix[SpeedUnit.MilesPerHour] = {};

    MeasurementsManager._speedMatrix[SpeedUnit.KilometersPerHour][SpeedUnit.KilometersPerHour] = 1;
    MeasurementsManager._speedMatrix[SpeedUnit.KilometersPerHour][SpeedUnit.MilesPerHour] = 0.621371;

    MeasurementsManager._speedMatrix[SpeedUnit.MilesPerHour][SpeedUnit.MilesPerHour] = 1;
    MeasurementsManager._speedMatrix[SpeedUnit.MilesPerHour][SpeedUnit.KilometersPerHour] = 1;

    MeasurementsManager._metricSpeedCoefficients = {};
    MeasurementsManager._metricSpeedCoefficients[SpeedUnit.KilometersPerHour] = 1;
    MeasurementsManager._metricSpeedCoefficients[SpeedUnit.MetersPerSecond] = 3.6;

    MeasurementsManager._imperialSpeedCoefficients = {};
    MeasurementsManager._imperialSpeedCoefficients[SpeedUnit.MilesPerHour] = 1;
  }

  // Gets the coefficient to go to minimum unit of its type (mm or inch)
  protected static getLengthCoefficient(unit: LengthUnit): KeyValuePair<LengthUnit, number> {
    switch (unit) {
      case LengthUnit.Millimeter:
      case LengthUnit.Centimeter:
      case LengthUnit.Decimeter:
      case LengthUnit.Meter:
        return { key: LengthUnit.Millimeter, value: MeasurementsManager._metricLengthCoefficients[unit] } as KeyValuePair<LengthUnit, number>;
      case LengthUnit.Inch:
      case LengthUnit.Feet:
      case LengthUnit.Yard:
        return { key: LengthUnit.Inch, value: MeasurementsManager._imperialLengthCoefficients[unit] } as KeyValuePair<LengthUnit, number>;
      default:
        throw new Error("This unit type doesn't have conversion implemented yet");
    }
  }

  protected static getVolumeCoefficient(unit: VolumeUnit): KeyValuePair<VolumeUnit, number> {
    switch (unit) {
      case VolumeUnit.CubicMillimeter:
      case VolumeUnit.CubicCentimeter:
      case VolumeUnit.CubicDecimeter:
      case VolumeUnit.CubicMeter:
      case VolumeUnit.Liter:
        return { key: VolumeUnit.CubicMillimeter, value: MeasurementsManager._metricVolumeCoefficients[unit] } as KeyValuePair<VolumeUnit, number>;
      case VolumeUnit.OunceUnitedKingdom:
      case VolumeUnit.PintUnitedKingdom:
      case VolumeUnit.GallonUnitedKingdom:
        return { key: VolumeUnit.OunceUnitedKingdom, value: MeasurementsManager._imperialVolumeCoefficients[unit] } as KeyValuePair<VolumeUnit, number>;
      case VolumeUnit.OunceUnitedStates:
      case VolumeUnit.PintUnitedStates:
      case VolumeUnit.GallonUnitedStates:
        return { key: VolumeUnit.OunceUnitedStates, value: MeasurementsManager._usVolumeCoefficients[unit] } as KeyValuePair<VolumeUnit, number>;
      case VolumeUnit.CubicInch:
      case VolumeUnit.CubicFoot:
        return { key: VolumeUnit.CubicInch, value: MeasurementsManager._cubicInchesVolumeCoefficients[unit] } as KeyValuePair<VolumeUnit, number>;
      default:
        throw new Error("This unit type doesn't have conversion implemented yet");
    }
  }

  protected static getWeightCoefficient(unit: WeightUnit): KeyValuePair<WeightUnit, number> {
    switch (unit) {
      case WeightUnit.Gram:
      case WeightUnit.Kilogram:
      case WeightUnit.Ton:
        return { key: WeightUnit.Gram, value: MeasurementsManager._metricWeightCoefficients[unit] } as KeyValuePair<WeightUnit, number>;
      case WeightUnit.Ounce:
      case WeightUnit.Pound:
      case WeightUnit.StoneUnitedStates:
      case WeightUnit.StoneUnitedKingdom:
        return { key: WeightUnit.Ounce, value: MeasurementsManager._imperialWeightCoefficients[unit] } as KeyValuePair<WeightUnit, number>;
      default:
        throw new Error("This unit type doesn't have conversion implemented yet");
    }
  }

  // Gets the coefficient to go to minimum unit of its type (km/h or mph)
  protected static getSpeedCoefficient(unit: SpeedUnit): KeyValuePair<SpeedUnit, number> {
    switch (unit) {
      case SpeedUnit.MetersPerSecond:
      case SpeedUnit.KilometersPerHour:
        return { key: SpeedUnit.KilometersPerHour, value: MeasurementsManager._metricSpeedCoefficients[unit] };
      case SpeedUnit.MilesPerHour:
        return { key: SpeedUnit.MilesPerHour, value: MeasurementsManager._imperialSpeedCoefficients[unit] };
      default:
        throw new Error("This unit type doesn't have conversion implemented yet");
    }
  }


  private static getRoundedValueInternal(sourceValue: number): number {
    return Math.round(sourceValue * MeasurementsManager.RoundingCoefficient) / MeasurementsManager.RoundingCoefficient;
  }

  private getRoundedValue(sourceValue: number): number {
    return this._options.roundingFn
      ? this._options.roundingFn(sourceValue, MeasurementsManager.RoundingPrecision)
      : MeasurementsManager.getRoundedValueInternal(sourceValue);
  }

  public lengthConvert(value: number): string {
    const fromCoMin = MeasurementsManager.getLengthCoefficient(LengthUnit.Meter);
    const toCoMin = MeasurementsManager.getLengthCoefficient(this._options.lengthUnit);
    return this.getRoundedValue(value * fromCoMin.value * MeasurementsManager._lengthMatrix[fromCoMin.key][toCoMin.key] / toCoMin.value).toFixed(2);
  }

  public volumeConvert(value: number): string {
    const fromCoMin = MeasurementsManager.getVolumeCoefficient(VolumeUnit.CubicMeter);
    const toCoMin = MeasurementsManager.getVolumeCoefficient(this._options.volumeUnit);
    return this.getRoundedValue(value * fromCoMin.value * MeasurementsManager._volumeMatrix[fromCoMin.key][toCoMin.key] / toCoMin.value).toFixed(2);
  }

  public weightConvert(value: number): string {
    const fromCoMin = MeasurementsManager.getWeightCoefficient(WeightUnit.Kilogram);
    const toCoMin = MeasurementsManager.getWeightCoefficient(this._options.weightUnit);
    return this.getRoundedValue(value * fromCoMin.value * MeasurementsManager._weightMatrix[fromCoMin.key][toCoMin.key] / toCoMin.value).toFixed(2);
  }

  public speedConvert(value: number): string {
    const fromCoMin = MeasurementsManager.getSpeedCoefficient(SpeedUnit.MetersPerSecond);
    const toCoMin = MeasurementsManager.getSpeedCoefficient(this._options.speedUnit);
    return this.getRoundedValue(value * fromCoMin.value * MeasurementsManager._speedMatrix[fromCoMin.key][toCoMin.key] / toCoMin.value).toFixed(2);
  }

  public measurementsTranslation(unit: string, short: boolean): string {
    return short
      ? this._i18nManager.translate(i18N[`CMN_UserUnit_${unit}_Short`])
      : this._i18nManager.translate(i18N[`CMN_UserUnit_${unit}_Regular`]);
  }

  public lengthTranslation(short = true): string {
    return this.measurementsTranslation(this._options.lengthUnitString, short);
  }

  public volumeTranslation(short = true): string {
    return this.measurementsTranslation(this._options.volumeUnitString, short);
  }

  public weightTranslation(short = true): string {
    return this.measurementsTranslation(this._options.weightUnitString, short);
  }

  public speedTranslation(short = true): string {
    return this.measurementsTranslation(this._options.speedUnitString, short);
  }

  public lengthWithUnit(value: number, short = true): string {
    return `${this.lengthConvert(value)} ${this.lengthTranslation(short)}`;
  }

  public volumeWithUnit(value: number, short = true): string {
    return `${this.volumeConvert(value)} ${this.volumeTranslation(short)}`;
  }

  public weightWithUnit(value: number, short = true): string {
    return `${this.weightConvert(value)} ${this.weightTranslation(short)}`;
  }

  public speedWithUnit(value: number, short = true): string {
    return `${this.speedConvert(value)} ${this.speedTranslation(short)}`;
  }

  public dimensionsConvert(length?: number | undefined, width?: number | undefined, height?: number | undefined, short = true): string {
    if (!length && !width && !height) {
      return '';
    }

    const internalLength = length ? this.lengthConvert(length) : '__';
    const internalWidth = width ? this.lengthConvert(width) : '__';
    const internalHeight = height ? this.lengthConvert(height) : '__';

    return `${internalLength}x${internalWidth}x${internalHeight} ${this.lengthTranslation(short)}`;
  }

  public differenceValue(firstValue: number, secondValue: number): number {
    return Math.round((firstValue - secondValue) * 100) / 100;
  }

  public differenceConvert(firstValue: number, secondValue: number): string {
    const difference = this.differenceValue(firstValue, secondValue);

    if (difference > 0) {
      return `+${difference}`;
    }

    if (difference === 0) {
      return '';
    }

    return difference.toString();
  }
}
