import { type IDateTimeManagerOptions } from '../contracts';
import { mapDateTimeFormatToInternal } from './dateTimeManagerInternals';

export class DateTimeManagerOptions implements IDateTimeManagerOptions {
  private _timeDisplayPattern = mapDateTimeFormatToInternal('HH:mm');
  private _dateDisplayPattern = mapDateTimeFormatToInternal('YYYY-MM-DD');

  constructor(options?: Partial<IDateTimeManagerOptions>) {
    if (options) {
      if (options.timeDisplayPattern) this.timeDisplayPattern = options.timeDisplayPattern;
      if (options.dateDisplayPattern) this.dateDisplayPattern = options.dateDisplayPattern;
      if (options.timeZone) this.timeZone = options.timeZone;
      if (options.locale) this.locale = options.locale;
    }
  }

  get timeDisplayPattern(): string { return this._timeDisplayPattern; }
  set timeDisplayPattern(value: string) {
    this._timeDisplayPattern = mapDateTimeFormatToInternal(value);
  }

  get dateDisplayPattern(): string { return this._dateDisplayPattern; }
  set dateDisplayPattern(value: string) {
    this._dateDisplayPattern = mapDateTimeFormatToInternal(value);
  }

  get dateTimeDisplayPattern(): string {
    return `${this.dateDisplayPattern} ${this.timeDisplayPattern}`;
  }

  timeZone = 'Etc/UTC';
  locale = 'en';
}

