import { CustomizationOptions, type ICustomizationOptions } from './index';

/**
 * Get the first valid customization value or return an empty string.
 * @param data Input data.
 * @returns First valid customization value or empty string.
 */
const getFistValidCustomizationValueOrEmpty = (data: null | undefined | string | string[]): string => {
  if (Array.isArray(data)) {
    for (const item of data) {
      if (item) {
        return item;
      }
    }
    return '';
  } else {
    return data || '';
  }
};

/**
 * Get the
 * @param values Dictionary of values.
 * @returns Customization options.
 */
export const getCustomizationOptionsFromDictionary = (values: { [id: string]: string | string[] }): Partial<ICustomizationOptions> => {
  const result = new CustomizationOptions();
  const keys = Object.keys(result);
  for (const key of keys) {
    const value = getFistValidCustomizationValueOrEmpty(values[key]);
    if (value) {
      result.overrideValues({ [key]: value } as Partial<ICustomizationOptions>);
    }
  }

  return new CustomizationOptions(result);
};
