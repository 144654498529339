/**
 * Warning:
 * This is an internal API and it can contain breaking changes at any time.
 * If this reaches the NPM package please DO NOT USE IT!
 * @param formatString The format string to map.
 * @returns The mapped format string.
 */
export const mapDateTimeFormatToInternal = (formatString: string): string => formatString
  .replace('dddd', 'cccc')
  .replace('MMMM', 'LLLL')
  .replace('YYYY', 'yyyy')
  .replace('DD', 'dd')
  .replace('Do', 'd') // luxon doesn't support ordinals
  .replace('D', 'd')
  .replace('MM', 'LL')
  .replace('A', 'a')
  .replace('tt', 'a');


