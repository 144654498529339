/**
 * A result of an operation that can be either successful or a failure.
 */
export class Result<T> {
  private _allowNull = false;
  private _error: Error | null = null;
  private _value: T | null = null;
  private _isSuccess = false;

  /**
   * Construct a new Result<T>.
   * @param allowNull Whether to allow the value to be null.
   */
  constructor(allowNull = false) {
    this._allowNull = allowNull;
  }

  public get isSuccess(): boolean { return this._isSuccess; };

  public get error(): Error|null {
    if (this._isSuccess) {
      throw new Error('Result is successful.');
    }

    if (this._error === null) {
      throw new Error('Result is failure but error was not set.');
    }

    return this._error;
  }

  public set error(value: Error|null) {
    this._error = value;
    this._isSuccess = false;
  }

  public get value(): T|null {
    if (!this._isSuccess) {
      throw new Error('Result is failure.');
    }

    if (this._value === null && !this._allowNull) {
      throw new Error('Result is success but value was not set.');
    }

    return this._value;
  }

  public set value(value: T|null) {
    this._value = value;
    this._isSuccess = true;
  }
}
