import { CookieUtilities } from '../../../../utilities/index';
import { type II18nManager, type IMessageBus } from '../../../../core-components';
import { VersionChecker } from './versionCheckerService';


/**
 * A version checker implementation that relies on a cookie to know if a new version is available.
 */
export class CookieVersionChecker extends VersionChecker {
  private _cookieName: string;
  private _cookieValue: string;
  /**
   * Constructor.
   * @param window Operating window object for class
   * @param eventBus Message Bus in which error messages are posted to
   * @param i18nManager For translating outputs
   * @param cookieName The name of the cookie to check.
   * @param cookieValue The value that denotes a new version is available.
   * @param refreshInterval The interval (in milliseconds) between checks.
   * @param autoRefreshDelay The interval (in milliseconds) before the application is automatically refreshed.
   */
  constructor(window: Window, eventBus: IMessageBus, i18nManager: II18nManager, cookieName: string, cookieValue: string, refreshInterval?: number, autoRefreshDelay?: number) {
    super(window, eventBus, i18nManager, refreshInterval ?? 5 * 60 * 1000, autoRefreshDelay); // 5 * 60 * 1000 = 5 minutes * 60 seconds * 1000 milliseconds
    this._cookieName = cookieName;
    this._cookieValue = cookieValue;
  }

  protected async isNewerVersionAvailable(): Promise<boolean> {
    return this._cookieValue === new CookieUtilities(this.window).get(this._cookieName);
  }

}
