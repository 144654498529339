import { IframeContentState, type IIframeContent, type IIframeMessage } from '../../contracts/index';
type MessageEventHandlerFunctionType = (e: MessageEvent) => void;

/**
 * Configuration options for IframeContent.
 */
export interface IIframeContentOptions {
  /**
   * The origin of the parent.
   */
  parentOrigin: string;
  /**
   * A handler method to be called whenever  data is received from the parent.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messageHandler?: (data: any) => void;
}

/**
 * Basic IIframeContent implementation.
 */
export class IframeContent implements IIframeContent {
  protected window: Window;
  private _iframeId: string;
  private _options: IIframeContentOptions;
  private _onMessageReceived: null | MessageEventHandlerFunctionType;
  private _messageQueue: IIframeMessage[];
  private _standalone: boolean;
  private _disposed: boolean;

  /**
   * Constructor
   * @param window Reference to the window object
   * @param options Configuration options
   */
  constructor(window: Window, options: IIframeContentOptions) {
    if (!window)
      throw new Error('The "window" argument is required.');

    if (typeof options !== 'object' || !options)
      throw new Error('The "options" argument is required.');

    if (typeof options.parentOrigin !== 'string' || options.parentOrigin.length === 0)
      throw new Error('Parent origin("parentOrigin") should be a non-empty string.');

    this.window = window;
    this._standalone = window === window.parent;
    this._options = options;
    this._iframeId = '';
    this._messageQueue = new Array<IIframeMessage>();
    this._disposed = false;
    if (this._standalone) {
      this._onMessageReceived = null;
    } else {
      this._onMessageReceived = this._windowMessageHandler.bind(this);
      this.window.addEventListener('message', this._onMessageReceived);
    }
  }

  /**
   * Initialize the component.
   */
  public initialize(): void {
    this.initializeCore();

    // Bypass the queue and initiate the handshake.
    this._sendMessage({ id: this._iframeId, state: IframeContentState.Mounted }, true);
  }

  private _windowMessageHandler(event: MessageEvent): void {
    if (event.origin !== this._options.parentOrigin)
      return;

    const messageData = event.data
      ? event.data as IIframeMessage
      : null;

    if (!messageData) {
      return;
    }

    // In case we do not have the iframeId it means handshake did not happen.
    if (!this._iframeId) {
      this._handShake(messageData);
    } else if (!messageData.state && messageData.data) {
      if (typeof this._options.messageHandler === 'function') {
        this._options.messageHandler(JSON.parse(messageData.data));
      }
    }
  }

  private _handShake(messageData: IIframeMessage) {
    if (messageData.id) {
      // Phase 2 of the handshake - we got the id.
      this._iframeId = messageData.id;

      // Send it again to notify parent.
      this._sendMessage({ id: this._iframeId, state: IframeContentState.Mounted });

      // Send the previously queued messages.
      this._flushMessages();
    }
    else {
      // Phase 1 of the handshake - we got the hash so send it back.
      this._sendMessage({ id: this._iframeId, state: IframeContentState.Mounted, data: messageData.data }, true);
    }
  }

  private _sendMessage(message: IIframeMessage, bypassQueue = false): void {
    if (this._standalone)
      return;

    if (this._iframeId && !message.id) {
      // Override the message id in case we have iframeId
      message.id = this._iframeId;
    }

    if (bypassQueue || message.id) {
      this.postMessageToParentCore(message);
    } else {
      this._messageQueue.push(message);
    }
  }

  private _flushMessages(): void {
    for (const [index] of this._messageQueue.entries()) {
      const msg = this._messageQueue[index];
      msg.id = this._iframeId;
      this.postMessageToParentCore(msg);
    }
  }

  /**
   * Initialize any necessary components.
   */
  protected initializeCore(): void { return; }

  /**
   * Send a message to the parent window.
   * @param message The message data.
   */
  protected postMessageToParentCore(message: IIframeMessage | string | Record<string, unknown>): void {
    this.window.parent.postMessage(message, this._options.parentOrigin);
  }

  /**
   * @inheritdoc
   */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public messageParent(data: any): void {
    this._sendMessage({
      id: this._iframeId,
      data: JSON.stringify(data)
    });
  }
  /**
   * @inheritdoc
   */
  public signalBusyState(isBusy: boolean): void {
    this._sendMessage({
      id: this._iframeId,
      state: isBusy
        ? IframeContentState.BeforeUpdate
        : IframeContentState.Updated
    });
  }
  /**
   * @inheritdoc
   */
  public async dispose(): Promise<void> {
    if (this._disposed)
      return;

    this._disposed = true;
    this.signalBusyState(true);

    if (this._onMessageReceived) {
      this.window.removeEventListener('message', this._onMessageReceived);
      this._onMessageReceived = null;
    }

    this._sendMessage({ id: '', state: IframeContentState.Destroyed });
  }
}
