/**
 * Get the names of the members of an enum.
 * @param theEnum The enum to get the values for.
 * @returns The names of the members of the enum.
 */
export const getEnumMembers = (theEnum: Record<string, unknown>): string[] => {
  const names: string[] = [];
  for (const n in theEnum) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (typeof (theEnum as any)[n] === 'number') {
      names.push(n);
    }
  }

  if (names.length === 0) {
    // We might be in the situation where we have an enum with string values.
    // Just push all the keys.

    for (const n in theEnum) {
      names.push(n);
    }
  }
  return names;
};
